import dynamic from 'next/dynamic'

import { PrpProvider } from '/contexts/prpContext'
import useProductResultPage from '/hooks/useProductResultPage'
import GetStaticPaths from '/lib/CSRshop/getStaticPaths'
import GetStaticProps from '/lib/CSRshop/getStaticProps'

const ProductResultPage = dynamic(() => import('/layout/productResultPage'))

export async function getStaticProps({ params }) {
  return await GetStaticProps(params)
}

export async function getStaticPaths({ params }) {
  return await GetStaticPaths(params)
}

const initialState = {}
const Shop = ({ allShopPageProps }) => {
  return (
    <>
      <PrpProvider initialState={allShopPageProps} initialParams={initialState}>
        <LayoutDiscriminator />
      </PrpProvider>
    </>
  )
}

const LayoutDiscriminator = () => {
  const isFacetResultPage = useProductResultPage((x) => x.isFacetResultPage)
  const layout = <ProductResultPage isFrp={isFacetResultPage} />

  return layout
}

export default Shop
